<template>
    <div>
        <Pop :clickClose="true" ref="pops">
            <div slot="popContent">
                <div class="btnGroup">
                    <div class="BtnGroupList">
                        <Btn :btnClass="index ? 'btnGroupBtn btnBt1' : 'btnGroupBtn'" v-for="(btn, index) in btnList" :key="index" @callBack="btn.callback">
                            <span slot="btnContent">
                                {{ btn.txt }}
                            </span>
                        </Btn>
                    </div>

                    <Btn btnClass="btnGroupBtn closeBtnGroup" @callBack="openPop">
                        <span slot="btnContent">取消</span>
                    </Btn>
                </div>
            </div>
        </Pop>
    </div>
</template>

<script>
export default {
    name: "BtnGroup",
    props: ["btnList"],
    methods: {
        openPop(type) {
            this.$refs.pops.changePop();
        },
    },
};
</script>

<style>
.btnGroup {
    padding: 0 0.2rem 0.3rem 0.2rem;
}

.btnGroupBtn {
    background: #fff !important;
    color: #13161b !important;
    font-size: 0.15rem;
    height: 0.35rem;
    line-height: 0.35rem;
    padding: 0.05rem 0;
}

.closeBtnGroup {
    margin-top: 0.2rem;
    border-radius: 0.05rem;
}

.btnBt1 {
    border-top: 1px solid #f5f5f5;
}

.BtnGroupList {
    border-radius: 0.05rem;
    overflow: hidden;
}
</style>
